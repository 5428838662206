<template>
  <div>
    <cbs-control-panel />
    <div v-if="isDev()">
      <h1>Cubus Dashboard</h1>
      <section id="dashboard-cubus">
        <b-row class="match-height">
          <b-col xl="4" md="6">
            <cbs-ecommerce-medal :data="data.congratulations" />
          </b-col>
          <b-col xl="8" md="6">
            <cbs-ecommerce-statistics :data="data.statisticsItems" />
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col>
            <cbs-revenue-widget v-if="false" />
          </b-col>
        </b-row>
        <b-row v-if="isAdmin" class="match-height">
          <b-col>
            <cbs-db-connections />
          </b-col>
        </b-row>
      </section>
      <div>
      <ecommerce v-if="isAdmin" />
    </div>
    </div>
  </div>
</template>

<script>

import { getUserData } from '@/auth/utils'
import { BCol, BRow } from 'bootstrap-vue'
import CbsEcommerceMedal from '@/cubus/dashboard/CbsEcommerceMedal.vue'
import CbsEcommerceStatistics from '@/cubus/dashboard/CbsEcommerceStatistics.vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import CbsDbConnections from '@/cubus/dashboard/CbsDbConnections.vue'
import CbsRevenueWidget from '@/cubus/components/revenue-widget/CbsRevenueWidget.vue'
import Ecommerce from '@/views/dashboard/ecommerce/Ecommerce.vue'
import CbsControlPanel from '@/cubus/components/dashboard/CbsControlPanel.vue'

export default {
  components: {
    CbsControlPanel,
    Ecommerce,
    CbsEcommerceMedal,
    CbsEcommerceStatistics,
    BCol,
    BRow,
    CbsDbConnections,
    CbsRevenueWidget,
  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    useCubus.setPageTitle('Dashboard')
    this.init()
    // data
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData()
        this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      })
  },
  methods: {
    init() {
      this.whoami()
    },
    whoami() {
      const token = localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName)
      if (token) {
        useJwt.whoami({ token })
          .then(response => {
            console.log('whoami response', response)
            if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            }
          })
          .catch(error => {
            console.log('whoami error', error)
            useCubus.toastError(this, error)
            this.$router.push({ name: 'auth-login' })
          })
      } else {
        this.$router.push({ name: 'auth-login' })
      }
    },
    isAdmin() {
      return useCubus.isAdmin()
    },
    isDev() {
      return useCubus.isDeveloper()
    },
  },
}
</script>

<style lang="scss">
</style>
