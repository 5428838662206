<template>
  <div>
    <div>
      <div v-if="widget" class="d-flex justify-content-between align-items-center">
        <div class="truncate">
          <h3 class="mb-25 font-weight-bolder" style="max-width: 100%">
            <span v-if="widget.param.title">{{ widget.param.title }}</span>
            <span v-else-if="srcObject">{{ srcObject.object.name }}</span>
            <span v-else><i class="small text-muted">no_data</i></span>
            &nbsp;<span class="small">{{ widget.param.metric }}</span>
          </h3>
          <span v-if="srcObject">{{ srcObject.object.descr }}</span>
        </div>
        <b-avatar v-if="srcObject" :variant="`light-${widget.param.color || 'primary'}`" size="45" class="cursor-pointer"
                  :href="'/cubus/object/' + srcObject.object.id"
        >
          <feather-icon size="21" :icon="widget.param.icon || 'LinkIcon'" />
        </b-avatar>
      </div>
    </div>
    <div v-show="false">
      <cbs-data-object v-if="widget.param.srcobject.value" :objectid="widget.param.srcobject.value" @load="loadSrcObject" />
    </div>
    <cbs-debug v-if="false" :context="this" />
  </div>
</template>

<script>
import {
  BAvatar, BCard, BCardBody,
} from 'bootstrap-vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'

export default {
  name: 'CbsWidgetObjectLink',
  components: {
    CbsDataObject,
    CbsDebug,
    BCard,
    BCardBody,
    BAvatar,
  },
  props: {
    widget: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      scalar: null,
      data: null,
      srcObject: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      // this.loadScalar()
    },
    loadScalar() {
      useJwt.query({
        query: {
          method: 'widget',
          param: { widgetid: this.widget.id },
        },
      })
        .then(response => {
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread) {
            this.threadScalar(response.data.thread)
          }
        })
        .catch(error => {
          useCubus.toastError(this, error)
        })
    },
    threadScalar(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: { threadname: thread },
        },
      })
        .then(response => {
          if (response.data.error) {
            useCubus.toastError(response.data.error)
          } else if (response.data.thread) {
            if (response.data.thread) {
              if (response.data.thread.status === 'done') {
                // this.scalar = response.data.thread.result.widget
                this.data = response.data.thread.result.widget
              } else if (response.data.thread.status === 'error') {
                useCubus.toastError(this, response.data.thread.error)
              } else {
                this.delayScalar(thread)
              }
            } else {
              useCubus.toastError(this, 'Thread not found.')
            }
          } else {
            useCubus.toastError(this, 'Load control panel is aborted.')
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error.toString())
        })
    },
    delayScalar(thread) {
      setTimeout(() => this.threadScalar(thread), 500)
    },
    loadSrcObject(obj) {
      this.srcObject = obj
    },
  },
}
</script>

<style scoped>

</style>
