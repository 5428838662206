<template>
  <div>
    <div>
      <div class="m-1">
        <h4>
          {{ widget.param.title }}
        </h4>
      </div>
      <div>
        <cbs-collapse :trigger="isData()">
          <vue-apex-charts v-if="data"
              :type="chartType()"
              height="400"
              :options="chartParam().chartOptions"
              :series="querySeries()"
          />
        </cbs-collapse>
      </div>
    </div>
    <cbs-debug v-if="false" :context="this" />
  </div>
</template>

<script>
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import VueApexCharts from 'vue-apexcharts'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'

export default {
  name: 'CbsWidgetChart',
  components: {
    CbsDebug,
    VueApexCharts,
    CbsCollapse,
  },
  props: {
    widget: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      rangePicker: ['2019-05-01', '2019-05-10'],
      chartColors: {
        column: {
          series1: '#826af9',
          series2: '#d2b0ff',
          bg: '#f8d3ff',
        },
        success: {
          shade_100: '#7eefc7',
          shade_200: '#06774f',
        },
        donut: {
          series1: '#ffe700',
          series2: '#00d4bd',
          series3: '#826bf8',
          series4: '#2b9bf4',
          series5: '#FFA1A1',
        },
        area: {
          series3: '#a4f8cd',
          series2: '#60f2ca',
          series1: '#2bdac7',
        },
      },
      query: null,
      data: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.loadQuery()
    },
    chartParam() {
      return {
        chartOptions: {
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              // horizontal: true,
              borderRadius: 5,
              columnWidth: '40%',
              dataLabels: {
                position: 'top',
              },
            },
          },
          dataLabels: this.chartDataLabels(),
          legend: {
            show: true,
            position: 'left',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            horizontalAlign: 'left',
          },
          stroke: this.chartStroke(),
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: this.queryCategories(),
          },
          yaxis: {
            // opposite: isRtl,
          },
          fill: {
            opacity: 1,
          },
          markers: this.chartMarkers(),
        },
      }
    },
    chartOptions() {
      return this.chartParam().chartOptions
    },
    loadQuery() {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'widget',
          param: {
            widgetid: this.widget.id,
          },
        },
      })
        .then(response => {
          // console.log('query response', response)
          // this.query = response
          if (response.data.thread) this.threadQuery(response.data.thread)
        })
        .catch(errQry => {
          console.log('query error', errQry)
        })
    },
    threadQuery(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: { threadname: thread },
        },
      })
        .then(response => {
          if (response.data.error) {
            useCubus.toastError(response.data.error)
          } else if (response.data.thread) {
            if (response.data.thread) {
              if (response.data.thread.status === 'done') {
                this.data = response.data.thread.result.widget
              } else if (response.data.thread.status === 'error') {
                useCubus.toastError(this, response.data.thread.error)
              } else {
                this.delayQuery(thread)
              }
            } else {
              useCubus.toastError(this, 'Thread not found.')
            }
          } else {
            useCubus.toastError(this, 'Load control panel is aborted.')
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error.toString())
        })
    },
    delayQuery(thread) {
      setTimeout(() => this.threadQuery(thread), 500)
    },
    querySeries() {
      if (this.data) {
        if (this.widget.param.stackentity) {
          return this.data.data.series
        }
        return [
          {
            name: this.widget.param.title,
            data: this.data.data.values,
          },
        ]
      }
      return []
    },
    queryCategories() {
      if (this.data) {
        return this.data.data.categories
      }
      return []
    },
    isData() {
      return this.data !== null
    },
    chartType() {
      return this.widget.param.charttype || 'bar'
    },
    chartStroke() {
      if (this.widget.param.charttype && this.widget.param.charttype === 'line') {
        return {
          show: true,
          stroke: 'straight',
        }
      }
      return {
        show: true,
        colors: ['transparent'],
      }
    },
    chartMarkers() {
      if (this.widget.param.charttype && this.widget.param.charttype === 'line') {
        return {
          size: 6,
        }
      }
      return {}
    },
    chartDataLabels() {
      if (this.widget.param.charttype && this.widget.param.charttype === 'line') {
        return {
          enabled: true,
          offsetY: -10,
          background: {
            enabled: false,
          },
        }
      }
      return {
        enabled: true,
        offsetY: -20,
        background: {
          enabled: false,
        },
      }
    },
  },
}
</script>

<style scoped>

</style>
