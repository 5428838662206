<template>
  <div>
    <div>
      <cbs-widget-scalar v-if="item.widget.param.widgettype.sid === 'scalar'" :widget="item.widget" />
      <cbs-widget-scalar-vertical v-else-if="item.widget.param.widgettype.sid === 'scalarvertical'" :widget="item.widget" />
      <cbs-widget-chart v-else-if="item.widget.param.widgettype.sid === 'chart'" :widget="item.widget" />
      <cbs-widget-donut v-else-if="item.widget.param.widgettype.sid === 'donut'" :widget="item.widget" />
      <cbs-widget-object-link v-else-if="item.widget.param.widgettype.sid === 'objectlink'" :widget="item.widget" />
      <cbs-widget-blank v-else :widget="item.widget" />
    </div>
    <cbs-debug v-if="false" :context="this" />
  </div>
</template>

<script>
import CbsWidgetScalar from '@/cubus/components/dashboard/CbsWidgetScalar.vue'
import CbsWidgetScalarVertical from '@/cubus/components/dashboard/CbsWidgetScalarVertical.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsWidgetBlank from '@/cubus/components/dashboard/CbsWidgetBlank.vue'
import CbsWidgetChart from '@/cubus/components/dashboard/CbsWidgetChart.vue'
import CbsWidgetDonut from '@/cubus/components/dashboard/CbsWidgetDonut.vue'
import CbsWidgetObjectLink from '@/cubus/components/dashboard/CbsWidgetObjectLink.vue'

export default {
  name: 'CbsDashboardItem',
  components: {
    CbsDebug,
    CbsWidgetScalarVertical,
    CbsWidgetScalar,
    CbsWidgetBlank,
    CbsWidgetChart,
    CbsWidgetDonut,
    CbsWidgetObjectLink,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style scoped>

</style>
