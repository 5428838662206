<template>
  <div>
    <b-row class="match-height">
      <b-col v-for="(item, itemidx) in row.items" :key="itemidx" :cols="cols(item)">
        <b-card>
          <cbs-dashboard-item :item="item" />
        </b-card>
      </b-col>
    </b-row>
    <cbs-debug v-if="false" :context="this" />
  </div>
</template>

<script>
import {
  BCard, BCol, BRow,
} from 'bootstrap-vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsDashboardItem from '@/cubus/components/dashboard/CbsDashboardItem.vue'

export default {
  name: 'CbsDashboardRow',
  components: {
    CbsDebug,
    BRow,
    BCol,
    CbsDashboardItem,
    BCard,
  },
  props: {
    row: {
      type: Object,
      default: null,
    },
  },
  methods: {
    cols(item) {
      if (item.widget.param.columns) {
        const cols = Number(item.widget.param.columns)
        if (cols > 0 && cols < 13) {
          return cols
        }
        return this.colsByType(item)
      }
      return this.colsByType(item)
    },
    colsByType(item) {
      if (item.widget.param.widgettype.sid === 'scalar') {
        return 3
      }
      if (item.widget.param.widgettype.sid === 'scalarvertical') {
        return 2
      }
      return 12
    },
  },
}
</script>

<style scoped>

</style>
