<template>
  <div>
    <b-card no-body>
      <div class="p-1">
        <span class="text-muted">{{ widget }}</span>
      </div>
    </b-card>
    <cbs-debug v-if="false" :context="this" />
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'

export default {
  name: 'CbsWidgetScalar',
  components: {
    CbsDebug,
    BCard,
  },
  props: {
    widget: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {}
  },
  created() {
    this.init()
  },
  methods: {
    init() {},
  },
}
</script>

<style scoped>

</style>
