<template>
  <div>
    <div class="text-center">
      <b-avatar
          class="mb-1"
          :variant="`light-${widget.param.color}`"
          size="45"
      >
        <feather-icon
            size="21"
            :icon="widget.param.icon"
        />
      </b-avatar>
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          <span v-if="data">{{ data.data.data.value }}</span>
          <span v-else><i class="small text-muted">no_data</i></span>
          &nbsp;<span class="small">{{ widget.param.metric }}</span>
        </h2>
        <span>{{ widget.param.title }}</span>
      </div>
    </div>
    <cbs-debug v-if="false" :context="this" />
  </div>
</template>

<script>
import {
  BAvatar, BCard,
} from 'bootstrap-vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'

export default {
  name: 'CbsWidgetScalarVertical',
  components: {
    CbsDebug,
    BCard,
    BAvatar,
  },
  props: {
    widget: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      scalar: null,
      data: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.loadScalar()
    },
    loadScalar() {
      useJwt.query({
        query: {
          method: 'widget',
          param: { widgetid: this.widget.id },
        },
      })
        .then(response => {
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread) {
            this.threadScalar(response.data.thread)
          }
        })
        .catch(error => {
          useCubus.toastError(this, error)
        })
    },
    threadScalar(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: { threadname: thread },
        },
      })
        .then(response => {
          if (response.data.error) {
            useCubus.toastError(response.data.error)
          } else if (response.data.thread) {
            if (response.data.thread) {
              if (response.data.thread.status === 'done') {
                this.data = response.data.thread.result.widget
              } else if (response.data.thread.status === 'error') {
                useCubus.toastError(this, response.data.thread.error)
              } else {
                this.delayScalar(thread)
              }
            } else {
              useCubus.toastError(this, 'Thread not found.')
            }
          } else {
            useCubus.toastError(this, 'Load control panel is aborted.')
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error.toString())
        })
    },
    delayScalar(thread) {
      setTimeout(() => this.threadScalar(thread), 500)
    },
  },
}
</script>

<style scoped>

</style>
