<template>
  <b-card
      v-if="data"
      class="card-tiny-line-stats"
      body-class="pb-50"
  >
    <!--    <b-card-header>
          <b-card-title></b-card-title>
          <b-card-text class="font-small-2 mr-25 mb-0">
            Updated 1 month ago
          </b-card-text>
        </b-card-header>-->
    <b-card-body>
      <h6>
        Revenue
        &nbsp;&nbsp;
        <feather-icon :icon="isChartUpdate ? 'PauseIcon' : 'PlayIcon'"
                      class="cursor-pointer"
                      size="18"
                      @click="toggleChartUpdate()" />
      </h6>
      <h2 class="font-weight-bolder mb-1">
        {{ value() }}
      </h2>
      <!-- chart -->
      <vue-apex-charts ref="refDbConnectionsChart"
                       height="70"
                       :options="statisticsProfit.chartOptions"
                       :series="data.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'

const $trackBgColor = '#EBEBEB'

export default {
  name: 'CbsRevenueWidget',
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
  },
  props: {},
  data() {
    return {
      statisticsProfit: {
        chartOptions: {
          chart: {
            type: 'bar',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: $trackBgColor,
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
            padding: {
              top: -30,
              bottom: -10,
            },
          },
          stroke: {
            width: 3,
          },
          colors: [$themeColors.primary],
          markers: {
            size: 2,
            colors: $themeColors.info,
            strokeColors: $themeColors.info,
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [
              {
                seriesIndex: 0,
                dataPointIndex: 5,
                fillColor: '#ffffff',
                strokeColor: $themeColors.info,
                size: 5,
              },
            ],
            shape: 'circle',
            radius: 2,
            hover: {
              size: 3,
            },
          },
          xaxis: {
            labels: {
              show: true,
              style: {
                fontSize: '0px',
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
      data: {
        series: [
          {
            data: [],
          },
        ],
      },
      isChartUpdate: false,
    }
  },
  created() {
    this.run()
  },
  methods: {
    value() {
      return this.data.series[0].data[this.data.series[0].data.length - 1]
    },
    run() {
      this.delayGet()
    },
    delayGet() {
      setTimeout(() => this.loadData(), 1000)
    },
    loadData() {
      useJwt.query({
        query: {
          method: 'dbconnections',
          param: {},
        },
      })
        .then(response => {
          // console.log('response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else {
            if (response.data.result) {
              // this.isChart = false
              this.data.series[0].data.push(response.data.result)
              if (this.data.series[0].data.length > 60) {
                this.data.series[0].data.splice(0, 1)
              }
              this.$refs.refDbConnectionsChart.updateSeries([{
                data: this.data.series[0].data,
              }], true, true)
              // this.isChart = true
            }
            if (this.isChartUpdate) this.delayGet()
          }
        })
        .catch(error => {
          console.log('error', error)
          useCubus.toastError(this, error)
        })
    },
    toggleChartUpdate() {
      this.isChartUpdate = !this.isChartUpdate
      if (this.isChartUpdate) this.run()
    },
  },
}
</script>
