<template>
  <div>
    <div>
      <h4 class="text-secondary">{{ board.name }}</h4>
      <section :id="'cbs_dashboard_' + uuid">
        <cbs-dashboard-row v-for="(row, rowidx) in board.rows" :row="row" :key="rowidx" />
      </section>
    </div>
    <cbs-debug v-if="false" :context="this" />
  </div>
</template>

<script>
import CubusService from '@/cubus/services/cubusService'
import CbsDashboardRow from '@/cubus/components/dashboard/CbsDashboardRow.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'

export default {
  name: 'CbsDashboard',
  components: {
    CbsDebug,
    CbsDashboardRow,
  },
  props: {
    board: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      uuid: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.uuid = CubusService.guid
    },
  },
}
</script>

<style scoped>

</style>
